import Element from "element-ui";
import router from "../../router";
import {chatMessage, EncryptMessage} from "./commons";

const constants = {
    ORDER_S_UPDATE_TOKEN:"order_s_update_token",//websocket收到指令:更新token
    ORDER_S_TIP:"order_s_tip",//websocket收到指令:提示
    ORDER_R_BINDING_CHANNEL:"order_r_binding_channel",//websocketd发送指令:绑定channelid
    ORDER_R_UPDATED_TOKEN:"order_r_updated_token",//websocketd发送指令: 已更新token
    ORDER_MESSAGE:"order_message",//websocket指令:
    ORDER_R_UPDATE_LOG:"order_r_update_log",//websocket发送指令:更新日志
    ORDER_S_UPDATE_LOG:"order_s_update_log",//websocket收到指令:更新日志
    ORDER_R_REQUEST:"order_r_request",//发送请求id
    ORDER_S_REQUEST:"order_s_request",//回复请求id
    ORDER_TOKEN_OUT:'token_out',
    ORDER_R_BINDING_EQUIPMENT:"order_r_binding_equipment",//websocketd发送指令:绑定设备成功
    ORDER_S_BINDING_EQUIPMENT:"order_s_binding_equipment", // 接收绑定设备成功：初始化播放
    ORDER_S_ONLINE_EQUIPMENT:"order_s_online_equipment", //接收设备在线指令，更新设备在线状态
    ORDER_S_UNONLINE_EQUIPMENT:"order_s_unonline_equipment",//接收设备离线指令，更新设备离线状态
    ORDER_UNLOCKED_ACCOUNT:'order_unlocked_account',
    TOKEN:"token",
    USER:"user",
    UNIT:"unit",
    APP:'app',
    APPS:'appList',
    TAGS:'tags',
    DICT:'dict',
    getDomain:()=>{
        if(location.host.includes("300")){
            return 'https://'+location.host.split(":")[0];
        }else{
            return 'https://'+location.host+'';
        }
    },
    CHAT:{
        appCode:'',
        socket: null,
        close:function(){
            toLogin("主动关闭socket！");
        },
        init: function(accountId,back) {
            let token=sessionStorage.getItem('AccessToken');
            if (window.WebSocket && token) {
                this.socket = new WebSocket('wss://'+location.host.split(":")[0]+':7000/ws');
                this.socket.onopen = () =>{
                    console.log("连接建立成功...");
                    sendMessage(this.socket,token,constants.ORDER_R_BINDING_CHANNEL,null,accountId);
                    if(back){
                        back()
                    }
                };
                this.socket.onclose = ()=> {
                    console.log("连接关闭,清除AccessToken...");
                    sessionStorage.removeItem('AccessToken');
                };
                this.socket.onerror = ()=> {
                    console.log("发生错误...");
                };
                this.socket.onmessage =(e)=>{
                    chatMessage(this,e.data);
                }
            } else {
                Element.Notification({
                    title: '温馨提示',
                    message: "浏览器不支持websocket协议...",
                    dangerouslyUseHTMLString: true,
                    type: 'warning',////"success","warning"
                    position: 'bottom-right',
                    duration:2000
                });
            }
        },
        send: function(order,target,msg) {
            let token=sessionStorage.getItem('AccessToken');
            if(token) {
                if(this.socket && this.socket.readyState==1){
                    sendMessage(this.socket,token,order,target,msg);
                }else{
                    this.init(sessionStorage.getItem('accountId'),()=>{
                        sendMessage(this.socket,token,order,target,msg);
                    })
                }
            }else{
                console.log('token不存在！禁止发送消息！');
                toLogin('token不存在！禁止发送消息！');
            }
        }
    }
};


function toLogin(msg){
    if(location.href.includes('equipment/play')) {
        let myEvent = new CustomEvent('socket-error',{
            "detail": msg,//可携带额外的数据
        });
        document.dispatchEvent(myEvent);
    }else{
        router.push({path: '/login', query: {}});
    }
}

function sendMessage(socket,token,order,target,msg){
     let obj = {name:order};
     if(target){obj.target=target;}
     if(msg){obj.data=msg;}
     //console.log('发送消息！',obj);
     let entity = EncryptMessage(token, obj);
     socket.send(JSON.stringify({entity,token}));
}



export default constants;

